
@import "general/base/variables";
@import "general/utils/mixins";
@import "general/utils/functions";

$theme-color:           #00d2b5;

a{
    &:hover{
        color: $theme-color;
    }
}

.scroll-top{
    &:hover{
        .scroll-top-icon {
            background-color: $theme-color;
        }
    }
}

.icon-img{ 
    color: $theme-color;
}

.text-primary{
    color: $theme-color !important;
}

.bg-primary{
    background: $theme-color !important;
}

.close {
    &:hover {
        color: $theme-color;
    }
}

.more-link {
    &:after {
        background: $theme-color;
    }
    &:hover {
        color: $theme-color;
    }
}


.btn-outline-primary{
    @include border(null, 1px, solid, $theme-color);
    color: $theme-color;
    position: relative;
    &:before {
        @include position(absolute,0,0,0,0,-1);
        content: "";
        background: $theme-color;
        border-radius: 3px;
        @include transform(scale(0));
        @include transition(all 0.3s ease-out);
      }
      &:hover,&:focus{ 
        color: $white !important;
        background: none;
        &:before{
          @include transform(scale(1));
        }
      }
}

.btn-outline-white{
    &:hover{
        color: $theme-color;
        background: $white;
    }
}

.btn-white{
    color: $theme-color;
}

.owl-carousel { 
    .owl-dots{
        .owl-dot:hover, .active{
        &:after{
            background: $theme-color;
        }
        }
    }
    .owl-nav {
        button.owl-next,
        button.owl-prev {
        color: $theme-color;
        }
    }
}

.preloader {
    .bar-loader {
        span{
            background: $theme-color;
        }
    }
}

.blog-box-1{
    .blog-title {
        &:hover {
          h6 {
            color: $theme-color;
          }
        }
    }
}

.header {
    &.link-nav{
        &.fixed-nav{
            .navbar-nav {
                .nav-link {
                    &.active,
                    &:hover {
                    color: $theme-color;
                    }
                }
            }
        }
    }
}


.swiper-pagination{
    &.pagination-white{
      .swiper-pagination-bullet:hover, .swiper-pagination-bullet-active {
          background: $theme-color;
      }
    }
  }